import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';

import './scss/index.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import MainRouter from './router';
import ScrollRestoration from './router/scrollRestoration';
ReactDOM.render(
  <BrowserRouter>
    <ScrollRestoration>
      <MainRouter />
    </ScrollRestoration>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
