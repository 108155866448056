import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { DropShadow } from '../components/svg-filter';
import { Nav } from './Nav';
import SmoothScroll from '../components/SmoothScroll';

const Home = React.lazy(() => import('../pages/Home'));
const FAQ = React.lazy(() => import('../pages/FAQ'));
const Legal = React.lazy(() => import('../pages/Legal'));

export default class MainRouter extends Component {
  render() {
    return (
      <>
        <DropShadow />
        <Helmet>
          <html lang="de" />
          <title>Certainly Desinfektionsmittel</title>
          <meta charset="utf-8" />
          <meta name="author" content="Synelution GmbH Klagenfurt" />
          <meta
            name="description"
            content="Certainly Hand- und Oberflächendesinfektion nach WHO-Rezeptur | empfohlene Formulierung gegen das Corona Virus (SARS-CoV-2)!"
          />
        </Helmet>
        <Nav />
        <a
          className="shop-btn align-center btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.fromaustria.com/de-AT/certainly/hand-u-flaechendesinfektionsmittel?sai=12884"
        >
          Zum Shop
        </a>
        <Suspense fallback={null}>
          <div className="main-content">
            {/* <SmoothScroll> */}
            <Switch>
              <Route exact path="/legal/:slug" component={Legal} />
              <Route exact path="/FAQ" component={FAQ} />
              <Route exact path="/" component={Home} />
            </Switch>
            {/* </SmoothScroll> */}
          </div>
        </Suspense>
      </>
    );
  }
}
