import React from 'react';

export const DropShadow = () => (
  <svg
    className="svg-hidden"
    height="0"
    width="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <filter
      id="drop-shadow"
      x="-50%"
      y="-50%"
      width="200%"
      height="200%"
      filterUnits="objectBoundingBox"
    >
      <feGaussianBlur in="SourceAlpha" stdDeviation="20" />
      <feOffset dx="8" dy="8" result="offsetblur" />
      <feFlood floodColor="rgba(0,0,0,0.3)" />
      <feComposite in2="offsetblur" operator="in" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </svg>
);
